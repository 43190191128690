import React from 'react';
import { LifeLine } from 'react-loading-indicators';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
export default class Retention extends React.Component {
    constructor(props) {
      super(props);
        console.log(props.dates)
      this.state = {isLoading: false,error:false, dates:this.props.dates, dataRetention:[],dataRetentionMain:[], dataRetention5Weeks:[]};
     
    }


    componentDidMount(){   

        this._loadPage()
    }

    _loadPage = () => {
        const token = localStorage.getItem('token');
       
        this.setState({
            isLoading:true
        }, () => {
            fetch('https://sondago.com/mobile/Admin/Stats/statsRetention.php',
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: 'token='+token+'&dates='+JSON.stringify(this.state.dates)
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                   
                    if(responseJsonFromServer.statusCode == 1){
               
                        this.setState({isLoading:false, 
                           dataRetention:responseJsonFromServer.dataRetention,
                           dataRetentionMain:responseJsonFromServer.dataRetentionMain,
                           dataRetention5Weeks:responseJsonFromServer.dataRetention5Weeks
                          
                       })
                    }else{
                        this.setState({error:true})
                        toast("TOKEN INCORRECT", {position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"});
                    }
                }).catch((error) =>  {
                    this.setState({error:true})
                    console.log(error)
                    
                    toast("Une erreur s'est produite", {position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"});
                });
        })
    }

    componentWillReceiveProps(props){
       if(JSON.stringify(props.dates) != JSON.stringify(this.props.dates)){
        // var dates = [props.dates[0].format('YYYY-MM-DD')];
        
        console.log('newDates', props.dates)
            this.setState({dates:props.dates}, () => {
                this._loadPage()
            })  
       } 
    }
 
    render() {
      
      return (
        <div style={{flex:1, display:'flex'}}>
            {this.state.isLoading ?
            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center'}}>
                <LifeLine color="white" size="medium" text="" textColor="" />
            </div>
            :
            <div style={{flex:1, display:'flex',marginBottom:'25px', flexDirection:'column'}}>
                <div style={{flex:1, display:'flex', columnGap:'25px', margin:'25px', marginBottom:'0px'}} className='cat'>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', flexDirection:'column',flex:0}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Evolution de la rétention</p>
                        <div style={{flexDirection:'column', display:'flex', flex:1}}>
                            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                <p style={{color:'white', fontSize:17, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode actuelle</p>
                                <p style={{color:Math.round((this.state.dataRetentionMain[0]?.lastDay/this.state.dataRetentionMain[0]?.total)*100) > Math.round((this.state.dataRetentionMain[1]?.lastDay/this.state.dataRetentionMain[1]?.total)*100) ? 'green' : 'red', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{Math.round((this.state.dataRetentionMain[0]?.lastDay/this.state.dataRetentionMain[0]?.total)*100)}%</p>
                                <p style={{fontSize:13, marginTop:0, paddingTop:0,color:Math.round((this.state.dataRetentionMain[0]?.lastDay/this.state.dataRetentionMain[0]?.total)*100) > Math.round((this.state.dataRetentionMain[1]?.lastDay/this.state.dataRetentionMain[1]?.total)*100) ? 'green' : 'red'}}>{Math.round((this.state.dataRetentionMain[0]?.lastDay/this.state.dataRetentionMain[0]?.total)*100) > Math.round((this.state.dataRetentionMain[1]?.lastDay/this.state.dataRetentionMain[1]?.total)*100) ? "+" : ""}{Math.round((this.state.dataRetentionMain[0]?.lastDay/this.state.dataRetentionMain[0]?.total)*100)-Math.round((this.state.dataRetentionMain[1]?.lastDay/this.state.dataRetentionMain[1]?.total)*100)}% que la periode précedente</p>
                            </div>
                            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                <p style={{color:'rgba(255,255,255,0.7)', fontSize:17, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode précédente</p>
                                <p style={{color:'rgba(255,255,255,0.7)', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{Math.round((this.state.dataRetentionMain[1]?.lastDay/this.state.dataRetentionMain[1]?.total)*100)}%</p>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Graphique</p>
                        {this.state.dataRetention.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                        <LineChart colors={[Math.round((this.state.dataRetentionMain[0]?.lastDay/this.state.dataRetentionMain[0]?.total)*100) > Math.round((this.state.dataRetentionMain[1]?.lastDay/this.state.dataRetentionMain[1]?.total)*100) ? 'green' : 'red', 'rgba(255,255,255,0.7)']} grid={{ vertical: true, horizontal: true }} color={'white'} borderRadius={15}  
                        xAxis={[{scaleType:'point', data: this.state.dataRetention[0].xAxis, }]}
                        series={[
                            {
                            data: this.state.dataRetention[0].series,
                           
                            }, {
                                data: this.state.dataRetention[1].series,
                               
                                },
                        ]}
                        /></ThemeProvider>}
                    </div>
                </div>         
                <div style={{flex:1, display:'flex', columnGap:'25px', margin:'25px', marginBottom:'0px'}} className='cat'>
                    <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                        <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Rétention sur 5 semaines en % (à partir de la semaine dernière complète)</p>
                        {this.state.dataRetention.length > 0 &&
                        <ThemeProvider theme={darkTheme}>                 
                            <LineChart colors={['white']} grid={{ vertical: true, horizontal: true }} color={'white'} borderRadius={15}  
                            xAxis={[{scaleType:'point', data: this.state.dataRetention5Weeks.xAxis, }]}
                            series={[
                                {
                                data: this.state.dataRetention5Weeks.series,
                            
                                }
                            ]}
                            /></ThemeProvider>}
                    </div>
                </div>         
            </div>
            }
        </div>
      );
    }
  }