import React from 'react';
import { LifeLine } from 'react-loading-indicators';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
export default class Inactives extends React.Component {
    constructor(props) {
      super(props);
        console.log(props.dates)
      this.state = {isLoading: false,error:false, dates:this.props.dates,dataInactive:[],dataInactiveAge:[], dataInactivePlatform:{},dataRemoved:[], deletedAccountNB:{}, deletedAges:[]};
     
    }


    componentDidMount(){   

        this._loadPage()
    }

    _loadPage = () => {
        const token = localStorage.getItem('token');
        console.log('dates', this.state.dates)
        this.setState({
            isLoading:true
        }, () => {
            fetch('https://sondago.com/mobile/Admin/Stats/statsUnregister.php',
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }),
                    body: 'token='+token+'&dates='+JSON.stringify(this.state.dates)
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    console.log(responseJsonFromServer.deletedAges)
                    if(responseJsonFromServer.statusCode == 1){
               
                        this.setState({isLoading:false, 
                            deletedAccountNB:responseJsonFromServer.deletedAccountNB,
                            dataInactive:responseJsonFromServer.dataInactive,
                            dataInactiveAge:responseJsonFromServer.dataInactiveAge,
                            dataInactivePlatform:responseJsonFromServer.dataInactivePlatform,
                            dataRemoved:responseJsonFromServer.dataRemoved,
                            deletedAges:responseJsonFromServer.deletedAges
                       })
                    }else{
                        this.setState({error:true})
                        toast("TOKEN INCORRECT", {position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light"});
                    }
                }).catch((error) =>  {
                    this.setState({error:true})
                    console.log(error)
                    
                    toast("Une erreur s'est produite", {position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"});
                });
        })
    }

    componentWillReceiveProps(props){
       if(JSON.stringify(props.dates) != JSON.stringify(this.props.dates)){
        // var dates = [props.dates[0].format('YYYY-MM-DD')];
        
        console.log('newDates', props.dates)
            this.setState({dates:props.dates}, () => {
                this._loadPage()
            })  
       }
    }
 
    render() {
        var percentDeleted =0;
        if(this.state.deletedAccountNB.length > 0){
            percentDeleted =Math.round(((this.state.deletedAccountNB[1]-this.state.deletedAccountNB[0])/this.state.deletedAccountNB[0])*100)
            if(percentDeleted > 0){
                percentDeleted= parseInt('-'+percentDeleted)
            }else{
                percentDeleted= parseInt(String(percentDeleted).replace('-', ''))
            }
        }
       
      return (
        <div style={{flex:1, display:'flex'}}>
            {this.state.isLoading ?
            <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center'}}>
                <LifeLine color="white" size="medium" text="" textColor="" />
            </div>
            :
            <div style={{flex:1, display:'flex',marginBottom:'25px', flexDirection:'column'}}>
                 <div style={{flex:1, display:'flex', columnGap:'25px',  margin:'25px', marginBottom:'0px'}}  className='cat'>
                    <div style={{display:'flex', flexDirection:'column', rowGap:25}}>      
                        <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                            <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Taux d'inactivité</p>
                            <div style={{flexDirection:'row', display:'flex', flex:1}}>    
                                <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                    <p style={{color:'rgba(255,255,255,0.7)', fontSize:17, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode précédente</p>
                                    <p style={{color:'rgba(255,255,255,0.7)', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{Math.round(((this.state.dataInactive[1]?.reconnectedSince-this.state.dataInactive[1]?.allBefore)/this.state.dataInactive[1]?.allBefore)*100)}%</p>
                                    <p style={{fontSize:13, marginTop:0, paddingTop:0,opacity:0, color:Math.round(((this.state.dataInactive[0]?.reconnectedSince-this.state.dataInactive[0]?.allBefore)/this.state.dataInactive[0]?.allBefore)*100) > Math.round(((this.state.dataInactive[1]?.reconnectedSince-this.state.dataInactive[1]?.allBefore)/this.state.dataInactive[1]?.allBefore)*100) ? 'green' : 'red'}}>{Math.round(((this.state.dataInactive[0]?.reconnectedSince-this.state.dataInactive[0]?.allBefore)/this.state.dataInactive[0]?.allBefore)*100) > Math.round(((this.state.dataInactive[1]?.reconnectedSince-this.state.dataInactive[1]?.allBefore)/this.state.dataInactive[1]?.allBefore)*100) ? "+" : ""}{Math.round(((this.state.dataInactive[0]?.reconnectedSince-this.state.dataInactive[0]?.allBefore)/this.state.dataInactive[0]?.allBefore)*100)-Math.round(((this.state.dataInactive[1]?.reconnectedSince-this.state.dataInactive[1]?.allBefore)/this.state.dataInactive[1]?.allBefore)*100)}% que la periode précedente</p>
                                </div>
                                <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                    <p style={{color:'white', fontSize:17, fontWeight:'bold', marginBottom:0, paddingBottom:0}}>Periode actuelle</p>
                                    <p style={{color:Math.round(((this.state.dataInactive[0]?.reconnectedSince-this.state.dataInactive[0]?.allBefore)/this.state.dataInactive[0]?.allBefore)*100) > Math.round(((this.state.dataInactive[1]?.reconnectedSince-this.state.dataInactive[1]?.allBefore)/this.state.dataInactive[1]?.allBefore)*100) ? 'green' : 'red', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{Math.round(((this.state.dataInactive[0]?.reconnectedSince-this.state.dataInactive[0]?.allBefore)/this.state.dataInactive[0]?.allBefore)*100)}%</p>
                                    <p style={{fontSize:13, marginTop:0, paddingTop:0,color:Math.round(((this.state.dataInactive[0]?.reconnectedSince-this.state.dataInactive[0]?.allBefore)/this.state.dataInactive[0]?.allBefore)*100) > Math.round(((this.state.dataInactive[1]?.reconnectedSince-this.state.dataInactive[1]?.allBefore)/this.state.dataInactive[1]?.allBefore)*100) ? 'green' : 'red'}}>{Math.round(((this.state.dataInactive[0]?.reconnectedSince-this.state.dataInactive[0]?.allBefore)/this.state.dataInactive[0]?.allBefore)*100) > Math.round(((this.state.dataInactive[1]?.reconnectedSince-this.state.dataInactive[1]?.allBefore)/this.state.dataInactive[1]?.allBefore)*100) ? "+" : ""}{Math.round(((this.state.dataInactive[0]?.reconnectedSince-this.state.dataInactive[0]?.allBefore)/this.state.dataInactive[0]?.allBefore)*100)-Math.round(((this.state.dataInactive[1]?.reconnectedSince-this.state.dataInactive[1]?.allBefore)/this.state.dataInactive[1]?.allBefore)*100)}% que la periode précedente</p>
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex',  flexDirection:'column', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', padding:25, alignItems:'center', justifyContent:'center'}} className='sub_cat'>
                            <p style={{color:'white', fontSize:15, fontWeight:'bold',marginBottom:20}}>Suppressions de compte Par tranche d'âge</p>
                            <ThemeProvider theme={darkTheme}>          
                                <PieChart  slotProps={{legend:{ hidden: true }, }} width={270} height={270}  margin={{ right: 0 }}
                                series={[
                                    {
                                        arcLabel: (item) => item.label+` (${Math.round(item.value)}%)`,
                                    data: [ 
                                        { id: 0, value: Math.round((this.state.deletedAges.age1/(this.state.deletedAges?.age1+this.state.deletedAges?.age2+this.state.deletedAges?.age3))*100), label: '15-17', color:'rgb(0, 186, 173)' },
                                        { id: 1, value: Math.round((this.state.deletedAges.age2/(this.state.deletedAges?.age1+this.state.deletedAges?.age2+this.state.deletedAges?.age3))*100), label: '18-21', color:"rgb(42, 123, 155)" },
                                        { id: 2, value: Math.round((this.state.deletedAges?.age3/(this.state.deletedAges?.age1+this.state.deletedAges?.age2+this.state.deletedAges?.age3))*100), label: '22+', color:"rgb(61, 61, 107)" },
                                    
                                    ],
                                    },
                                ]}
                                sx={{
                                    [`& .${pieArcLabelClasses.root}`]: {
                                    fontWeight: 'bold',
                                    color:'white'
                                    },
                                }}
                            
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                    <div style={{display:'flex', flex:1, flexDirection:'column', rowGap:25}}>       
                        <div style={{display:'flex', flex:1, flexDirection:'column', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', padding:25, alignItems:'center', justifyContent:'center'}}>
                            <p style={{color:'white', fontSize:15, fontWeight:'bold',marginBottom:20}}>Inactivité Par plateforme</p>
                        
                            <ThemeProvider theme={darkTheme}>          
                                <PieChart  slotProps={{legend:{ hidden: true }, }} width={270} height={270}  margin={{ right: 0 }}
                                series={[
                                    {
                                        arcLabel: (item) => item.label+` (${Math.round(item.value)}%)`,
                                    data: [
                                        { id: 0, value: Math.round((this.state.dataInactivePlatform[0]?.inactiveIOS/(this.state.dataInactivePlatform[0]?.inactiveIOS+this.state.dataInactivePlatform[0]?.inactiveAndroid))*100), label: 'IOS', color:'rgb(240, 83, 19)' },
                                        { id: 1, value: Math.round((this.state.dataInactivePlatform[0]?.inactiveAndroid/(this.state.dataInactivePlatform[0]?.inactiveIOS+this.state.dataInactivePlatform[0]?.inactiveAndroid))*100), label: 'Android', color:"rgb(56, 161, 78)" },
                                    
                                    
                                    ],
                                    },
                                ]}
                                sx={{
                                    [`& .${pieArcLabelClasses.root}`]: {
                                    fontWeight: 'bold',
                                    color:'white'
                                    },
                                }}
                            
                                />
                            </ThemeProvider>
                        </div>
                        <div style={{display:'flex', flex:1, flexDirection:'column', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', padding:25, alignItems:'center', justifyContent:'center'}}>
                            <p style={{color:'white', fontSize:15, fontWeight:'bold',marginBottom:20}}>Inactivité Par tranche d'âge</p>
                        
                            <ThemeProvider theme={darkTheme}>          
                                <PieChart  slotProps={{legend:{ hidden: true }, }} width={270} height={270}  margin={{ right: 0 }}
                                series={[
                                    {
                                        arcLabel: (item) => item.label+` (${Math.round(item.value)}%)`,
                                    data: [
                                        { id: 0, value: Math.round((this.state.dataInactiveAge[0]?.inactiveAge1/(this.state.dataInactiveAge[0]?.inactiveAge1+this.state.dataInactiveAge[0]?.inactiveAge2+this.state.dataInactiveAge[0]?.inactiveAge3))*100), label: '15-17', color:'rgb(0, 186, 173)' },
                                        { id: 1, value: Math.round((this.state.dataInactiveAge[0]?.inactiveAge2/(this.state.dataInactiveAge[0]?.inactiveAge1+this.state.dataInactiveAge[0]?.inactiveAge2+this.state.dataInactiveAge[0]?.inactiveAge3))*100), label: '18-21', color:"rgb(42, 123, 155)" },
                                        { id: 2, value: Math.round((this.state.dataInactiveAge[0]?.inactiveAge3/(this.state.dataInactiveAge[0]?.inactiveAge1+this.state.dataInactiveAge[0]?.inactiveAge2+this.state.dataInactiveAge[0]?.inactiveAge3))*100), label: '22+', color:"rgb(61, 61, 107)" },
                                    
                                    ],
                                    },
                                ]}
                                sx={{
                                    [`& .${pieArcLabelClasses.root}`]: {
                                    fontWeight: 'bold',
                                    color:'white'
                                    },
                                }}
                            
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', rowGap:25}}  className='cat' id="deleted_account">
                        <div style={{ display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'20px', borderRadius:'15px', flexDirection:'column'}} className='sub_cat'>
                            <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Suppressions de compte</p>
                            {this.state.dataRemoved.length > 0 &&
                            <ThemeProvider theme={darkTheme}>                 
                            <LineChart colors={[ 'white', 'rgba(255,255,255,0.3)']} grid={{ vertical: true, horizontal: true }} color={'white'} borderRadius={15}  
                            xAxis={[{scaleType:'point', data: this.state.dataRemoved[0].xAxis, }]}
                            series={[
                                {
                                data: this.state.dataRemoved[0].series,
                            
                                }, {
                                    data: this.state.dataRemoved[1].series,
                                
                                    },
                            ]}
                            /></ThemeProvider>}
                        </div>
                        <div style={{display:'flex', backgroundColor:'rgba(0,0,0,0.3)',padding:'50px', borderRadius:'15px', flexDirection:'column',}}  className='sub_cat'>
                            <p style={{color:'white', fontSize:17, fontWeight:'bold'}}>Taux de suppressions de compte en rapport à la période précedente</p>
                            <div style={{flexDirection:'column', display:'flex', flex:1}}>
                                <div style={{display:'flex', flex:1, alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                    <p style={{color:percentDeleted <= 0 ? 'green' : 'red', fontSize:40, fontWeight:'bold', padding:10, margin:0}}>{percentDeleted > 0 ? '+' : ''}{percentDeleted}%</p>
                                    <p style={{fontSize:13, marginTop:0, paddingTop:0,color: 'white'}}>{this.state.deletedAccountNB[0]} comptes supprimés pour la période contre {this.state.deletedAccountNB[1]} pour la periode précedente</p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                
            </div>
            }
        </div>
      );
    }
  }